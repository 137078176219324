<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	import GoogleLogin from 'vue-google-login';
	import facebookLogin from 'facebook-login-vuejs';
	export default {
		extends: BaseVue,
		components: {
			facebookLogin,GoogleLogin
		},
		data() {
			return {
				input:{},
				params: {
					clientId: '228499823754-glc0vf2m8l5gmhk4odsbgkunedciis4o.apps.googleusercontent.com'
				},
			}
		},
		async mounted() {
			await this.ready();
			setTimeout(() => { 
				SEMICOLON.header.init();
				SEMICOLON.widget.extras(); }, 300)
			this.$set(this.$root, 'page', this);
			this.$set(this.input, 'remember',true)
		},
		computed: {
			mrValidation() {
				return this.$root.config.mrValidation.login
			}
		},
		methods: {
			onSubmit(){
				Gen.apirest("/login", this.input, (err,resp)=>{
					if(err) return this.$refs.info.show(err.resp.message, "warning")
					
					if(this.input.remember){
						Gen.putCookie("fotk",resp.token)
						Gen.putStorage("fotk",resp.token)
					}else{
						Gen.putCookie("fotk",resp.token)
					}

					Gen.putStorage("fo_user",resp.user)
					this.$root.user = resp.user
					this.$router.push({
						name:'Dashboard'
					})
				},"POST")
			},
			onSuccess(googleUser) {
				let profile = googleUser.getBasicProfile();
				let id = profile.getId()
				// let id_token = googleUser.getAuthResponse().id_token;
				this.onSubmitGoogle(id)
			},
			onFailure(){

			},
			onSubmitFb(id) {
				Gen.apirest('/login-facebook', {
					id: id
				}, (err, resp) => {
					if (err) return this.$refs.info.show(err.responseJSON.message, "warning")
					Gen.putCookie("fotk", resp.token)
					this.$root.user = resp.user
					Gen.putStorage("fo_user", resp.user)
					this.$router.push({
						name: "Dashboard"
					})
				}, 'POST')
			},
			onSubmitGoogle(id) {
				Gen.apirest('/login-google', { id: id }, (err, resp) => {
					if (err) return this.$refs.info.show(err.responseJSON.message, "warning")
					Gen.putCookie("fotk", resp.token)
					this.$root.user = resp.user
					Gen.putStorage("fo_user", resp.user)
					this.$router.push({
						name: "Dashboard"
					})
				}, 'POST')
			},
			getUserData() {
				FB.login((response)=> {
					if (response.authResponse) {
						console.log('Welcome!  Fetching your information.... ');
						FB.api('/me',  (response)=> {
							// console.log('Good to see you, ' + response.name + '.');
							this.onSubmitFb(response.id)
						});
					} else {
						console.log('User cancelled login or did not fully authorize.');
					}
				});
			},
			sdkLoaded(payload) {
				this.isConnected = payload.isConnected
				this.FB = payload.FB
				if (this.isConnected) this.getUserData()
			},
			onLogin() {
				this.isConnected = true
				this.getUserData()
			},
			onSignIn(){

			},
			onLogout() {
				this.isConnected = false
			},
		},
	};
</script>
<template>
	<section id="content" style="background-color: #000; overflow: visible">
		<div class="content-wrap pt-0 pb-0">
			<div id="section-about" class="section page-section bg_0f181b nobottommargin notopmargin clearfix">
				<div class="container clearfix">
					<div class="row  justify-content-center dark">
						<div class="col-md-6 col-sm-6 col-lg-5">
							<div class="wrap_login">
								<h2>{{web.lbl_sign_in}}</h2>
								<h4>{{web.lbl_sign_in_access}}</h4>
								<VForm @resp="onSubmit" action="post">
								<VInfo ref="info"></VInfo>
								<div class="row">
									<div class="col-md-12 mb-2">
										<label class="lbl_field">
											<span class="icon-line2-envelope"></span>
										</label>
										<div class="form-group">
											<input type="email" name="email" v-model="input.email" onkeydown="return emailKey(event)" class="frm_custom_sign padleft_form" v-bind="validation('email')" placeholder="Email Address">
											<LabelError name="email"></LabelError>
										</div>
									</div>
									<div class="col-md-12">
										<label class="lbl_field">
											<span class="icon-lock1"></span>
										</label>
										<div class="form-group">
											<input type="password" id="password" name="password" v-model="input.password" v-bind="validation('password')" class="frm_custom_sign padleft_form" placeholder="Password">
											<LabelError name="password"></LabelError>
										</div>
									</div>
									<div class="col-md-12 mt-2 mb-4">
										<div class="row">
											<div class="col-md-7 col-7">
												<div>
													<input id="checkbox-10" v-model="input.remember" class="checkbox-style" name="checkbox-10" type="checkbox" checked>
													<label for="checkbox-10" class="checkbox-style-2-label">{{web.lbl_keep_sign_in}}</label>
												</div>
											</div>
											<div class="col-md-5 col-5">
												<p class="text-right">
													<router-link :to="{name:'ForgotPassword'}">
														{{web.lbl_forgot_password}}?
													</router-link>
												</p>
											</div>
										</div>
									</div>
									<div class="col-md-12 text-center">
										<p><button type="submit" class="btn_act w_100">{{web.lbl_sign_in}} <i class="icon-line-arrow-right"></i></button></p>
									</div>
									<div class="col-md-12">
										<div class="divider divider-short  mb-3 divider-center">{{web.lbl_or}}
										</div>
									</div>
										<div class="col-md-12 text-center">
										<GoogleLogin class="btn_gray_sosmed ml-0" :params="params" :onSuccess="onSuccess"
										:onFailure="onFailure">
											<i class="icon-google1"></i>
										</GoogleLogin>
										<facebook-login class="btn_gray_sosmed px-0" appId="739123363526240" @login="getUserData" loginLabel=""></facebook-login>
									</div>
									<div class="col-md-12">
										<p class="text-center mt-4">
											<router-link :to="{name:'Signup'}" class="borbot">
												{{web.lbl_create_account}}
											</router-link>
										</p>
									</div>
								</div>
								</VForm>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="clear"></div>
		</div>
	</section>
</template>
